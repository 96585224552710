import React from "react"
import { Link } from 'gatsby'
import styles from "./searchResults.module.scss"
import ArticleBox from '../components/ArticleBox'
import find from 'lodash/find'
import * as _h from '../helpers'
import sortBy from 'lodash/sortBy'
import reverse from 'lodash/reverse'


const SearchResults = ({ query, results, allPosts }) => {
  let monthSeparator = null

  const isNewMonth = date => {
    let parts = date.split('-')
    let actualMonth = parts[0] + '-' + parts[1]

    if (monthSeparator === actualMonth) {
      return false
    }

    monthSeparator = actualMonth
    return true
  }

  results = reverse(sortBy(results, 'kozzeteve'))

  return (
    <section className={styles.results}>
      {!results.length &&
        <p className={styles.resultMessage}>Nincs találat</p>
      }
      {!!results.length && results[0] == "progress" &&
        <p className={styles.resultMessage}>A keresés folyamatban van...</p>
      }
      {!!results.length && results[0] != "progress" && (
        results.map(({ cim, slug, lead, kozzeteve, szerzo, fejleckep, id }) => {

            let fullPost = find(allPosts.edges, function(post) {
              return post.node.id == id;
            })

            return (
              <ArticleBox
                titleBefore={isNewMonth(fullPost.node.kozzeteve) && _h.formatDate(monthSeparator)}
                szerzo={szerzo}
                image={fullPost.node.fejleckep}
                key={id}
                type="other"
                cim={cim}
                lead={fullPost.node.leadNode.childMarkdownRemark.html}
                linkTo={fullPost.node.slug}
              />
            )
          }
        )
      )}
    </section>
  )
}


export default SearchResults