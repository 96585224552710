import React, { useState, useEffect } from "react"
import debounce from "lodash/debounce"
import throttle from "lodash/throttle"
import filter from "lodash/filter"
import Layout from "../components/layout"
import SearchForm from "../components/searchForm"
import SearchResults from "../components/searchResults"
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styles from "./search.module.scss"
import 'url-search-params-polyfill';

const Search = ({ data, location }) => {
  let resultsVisible = false
  const [results, setResults] = useState([])

  const searchQuery = {
    term: new URLSearchParams(location.search).get("keywords") || "",
    author: new URLSearchParams(location.search).get("author") || "",
    month: new URLSearchParams(location.search).get("month") || "",
    favOnly: new URLSearchParams(location.search).get("favOnly") || "",
  }

  useEffect(() => {
    setResults(["progress"])
    if (window.__LUNR__) {
     const debouncedSearch = debounce(async () => {
        const lunr = await window.__LUNR__.__loaded
        let term = ""
        if (searchQuery.term.trim() && searchQuery.term.trim().length > 2) term += "+" + searchQuery.term.trim().replace(" ", "* +") + "*"
        if (searchQuery.author) term += " +szerzo:" + searchQuery.author
        if (searchQuery.month) term += " +kozzeteve:" + searchQuery.month

        const refs = lunr.en.index.search(term)
        let posts = refs.map(({ ref }) => lunr.en.store[ref])

        if (posts.length && searchQuery.favOnly == "true") {
          const favPosts = localStorage.getItem('favPosts') || ''
          posts = filter(posts, (post) => {
            const postId = post.id.split('-')[1]
            return favPosts.indexOf(postId) != -1
          })
        }

        setResults(posts)
      }, 200)

      debouncedSearch()
    }

  }, [location.search])

  const defaultMetadata = data.datoCmsSite.globalSeo
  const pageTitle = defaultMetadata.siteName + defaultMetadata.titleSuffix
  const pageDescription = defaultMetadata.fallbackSeo.description

  return (
    <Layout location={location}  readOnlyAPIToken={data.site.siteMetadata.readOnlyAPIToken} >

      <Helmet
        htmlAttributes={{ lang: 'hu' }}
        meta={[{ name: 'description', content: pageDescription }]}
        title={pageTitle}
      />

      <h1 className={styles.title}>Archívum</h1>

      <SearchForm query={searchQuery} allPosts={data.allDatoCmsCikkek}/>
      <SearchResults query={searchQuery} results={results} allPosts={data.allDatoCmsCikkek} />
    </Layout>
  )
}

export const disableCorePrefetching = () => true

export default Search

export const pageQuery = graphql`
  query {
    datoCmsSite {
      globalSeo {
        siteName
        titleSuffix
        fallbackSeo {
          title
          description
        }
      }
    }
    site {
      siteMetadata {
        title
        productionMode
        fullAPIToken
        readOnlyAPIToken
      }
    }
    allDatoCmsCikkek (sort: {
      fields: kozzeteve
      order: DESC
    }){
      edges {
        node {
          id
          cim
          slug
          kozzeteve
          szerzo
          leadNode {
            childMarkdownRemark {
              html
            }
          }
          fejleckep {
            url
          }
        }
      }
    }
  }
`