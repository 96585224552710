import React from "react"
import { navigate } from "gatsby"
import * as _h from '../helpers'
import styles from "./searchForm.module.scss"


const SearchForm = ({ query, allPosts }) =>{

  let authors = []
  let months = []

  const search = (e) => {
    let term = document.getElementById("searchTerm").value || ''
    let month = document.getElementById("searchMonth").value || ''
    let author = document.getElementById("searchAuthor").value || ''

    let favOnly = document.getElementById("searchFavOnly").checked ? true : false

    navigate(`/search?keywords=${encodeURIComponent(term)}&month=${month}&author=${author}&favOnly=${favOnly}`)
  }

  for (let i = 0; i < allPosts.edges.length; i++) {
    let post = allPosts.edges[i].node
    let monthParts = post.kozzeteve.split('-')
    let month = monthParts[0] + '-' + monthParts[1]

    if (authors.indexOf(post.szerzo) == -1) authors.push(post.szerzo)
    if (months.indexOf(month) == -1) months.push(month)
  }

  return (
    <form className={styles.searchFields}>
      <div className={styles.searchField}>
        <label>Kifejezés:</label>
        <input
          id="searchTerm"
          type="text"
          value={query.term}
          onChange={search}
          placeholder="Min. 3 betű szükséges"
        />
      </div>
      <div className={styles.searchField}>
        <label>Hónap:</label>
        <select
          id="searchMonth"
          onChange={search}
          value={query.month}>
          <option value=''>Nincs beállítva</option>
          {months.map((month, index) => {
            return <option key={month + index} value={month.replace('-', '')}>{_h.formatDate(month)}</option>
          })}
        </select>
      </div>

      <div className={styles.searchField}>
        <label>Szerző:</label>
        <select
          id="searchAuthor"
          onChange={search}
          value={query.author}>
          <option value=''>Nincs beállí­tva</option>
          {authors.map((author, index) => {
            return <option key={author + index} value={author}>{author}</option>
          })}
        </select>
      </div>

      <div className={styles.searchFieldFavs}>
        <label>
          <input
            id="searchFavOnly"
            type="checkbox"
            onChange={search}
            checked={query.favOnly == "true" ? 'checked' : ''}/>
          Csak a mentett cikkek megjelenítése
        </label>
      </div>
    </form>
  )
}


export default SearchForm



